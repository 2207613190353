import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import "./Home.css";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabContainer from 'react-bootstrap/TabContainer';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import { Nav, Row, Col } from "react-bootstrap";

export default function Home() {
  const [members, setMembers] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
  
      try {
        const members = await loadmembers();
        setMembers(members.sort(dynamicSort("memberSurName")));
      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated]);
  
  function loadmembers() {
    return API.get("members", "/members");
  }

  function dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }


  function renderLander() {
    return (
      <div className="lander">
        <Image src="hmt-logo.png" roundedCircle />
        <h1>vereinsmanager.io</h1>
        <p className="text-muted">vereinsverwaltung leicht gemacht</p>
      </div>
    );
  }

  function renderNewMemberButton() {
    return (
      <LinkContainer to="/members/new">
        <ListGroup.Item action className="py-3 text-nowrap text-truncate">
          <BsPencilSquare size={17} />
            Neues Mitglied hinzufügen
        </ListGroup.Item>
      </LinkContainer>
    )
  }

  function renderMembersTable(memberDisplayStatus) {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Vorname</th>
            <th>Nachname</th>
            <th>Eintrittsdatum</th>
            <th>DVG Nummer</th>
            <th>Mitglieds-Typ</th>
          </tr>
        </thead>
        <tbody>
        {members
          .map(({ memberId, memberFirstName, memberSurName, memberType, memberEntryDay, dvgNumber, memberStatus }) => {
            if (memberStatus === memberDisplayStatus) {
              return (
                <LinkContainer key={memberId} to={`/editmember/${memberId}`}>
                  <tr>
                    <td>{memberFirstName.trim().split("\n")[0]}</td>
                    <td>{memberSurName.trim().split("\n")[0]}</td>
                    <td>{memberEntryDay}</td>
                    <td>{dvgNumber}</td>
                    <td>{memberType}</td>
                  </tr>
                </LinkContainer>
              )
            } else {
              return (null)
            }
          })
        }
        </tbody>
      </Table>
    )
  }

  function renderMembers() {
    return (
      <div className="members">
        <ListGroup>{!isLoading &&  <TabContainer id="member-control-left-tab" defaultActiveKey="activMembers">
          <Row>
            <Col sm={2}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  {renderNewMemberButton()}
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="activMembers">Aktive Mitglieder</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="inactivMembers">Inaktive Mitglieder</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="registeredMembers">Registrierte Mitglieder</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="deletedMembers">Gelöschte Mitglieder</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={6}>
              <TabContent>
                <TabPane eventKey="activMembers">
                  {renderMembersTable("active")}
                </TabPane>
                <TabPane eventKey="inactivMembers">
                  {renderMembersTable("inactive")}
                </TabPane>
                <TabPane eventKey="registeredMembers">
                  {renderMembersTable("reported")}
                </TabPane>
                <TabPane eventKey="deletedMembers">
                  {renderMembersTable("deleted")}
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </TabContainer>}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderMembers() : renderLander()}
    </div>
  );
}