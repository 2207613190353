import { Storage } from "aws-amplify";

export async function s3Upload(file, memberSurName, memberFirstName, type) {
  const filename = memberSurName + '-' + memberFirstName + '/' + type + '-' + Date.now() + '-' + file.name;

  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type,
  });

  return stored.key;
}