import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import config from "../config";
import "./NewMember.css";
import { API } from "aws-amplify";
import { s3Upload } from "../libs/awsLib";
import { Row, Col } from "react-bootstrap";

// Styling
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
}));
export default function NewMember() {
  const classes = useStyles();
  const MemberRequest = useRef(null);
  const DebitAuthorization = useRef(null);
  const AdditionalSheet = useRef(null);
  const [memberFirstName, setMemberFirstName] = useState("");
  const [memberSurName, setMemberSurName] = useState("");
  const [memberType, setMemberType] = useState("");
  const [memberStreet, setMemberStreet] = useState("");
  const [memberCity, setMemberCity] = useState("");
  const [memberPostCode, setMemberPostCode] = useState("");
  const memberStatus = "inactive";
  const [memberBirthDay, setMemberBirthDay] = useState("");
  const [memberEntryDay, setMemberEntryDay] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  function validateForm() {
    return memberSurName.length > 0;
  }
  function handleFileMemberRequestChange(event) {
    MemberRequest.current = event.target.files[0];
  }
  function handleFileDebitAuthorizationChange(event) {
    DebitAuthorization.current = event.target.files[0];
  }
  function handleFileAdditionalSheetChange(event) {
    AdditionalSheet.current = event.target.files[0];
  }

  async function handleSubmit(event) {
    event.preventDefault();

    // Mitgliedsantrag
    if (MemberRequest.current && MemberRequest.current.size > config.MAX_ATTACHMENT_SIZE) {
        alert(
            `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
            1000000} MB.`
        );
        return;
    }
    // Bankeinzug
    if (DebitAuthorization.current && DebitAuthorization.current.size > config.MAX_ATTACHMENT_SIZE) {
        alert(
            `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
            1000000} MB.`
        );
        return;
    }
    if (AdditionalSheet.current && AdditionalSheet.current.size > config.MAX_ATTACHMENT_SIZE) {
        alert(
            `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
            1000000} MB.`
        );
        return;
    }

    setIsLoading(true);

    try {
        const attachmentMemberRequest = MemberRequest.current ? await s3Upload(MemberRequest.current, memberSurName, memberFirstName, 'antrag') : null;
        const attachmentDebitAuthorization = DebitAuthorization.current ? await s3Upload(DebitAuthorization.current, memberSurName, memberFirstName, 'bankeinzug') : null;
        const attachmentAdditionalSheet = AdditionalSheet.current ? await s3Upload(AdditionalSheet.current, memberSurName, memberFirstName, 'zusatz') : null;
        await createMember({ memberFirstName, memberSurName, memberType, memberStreet, memberCity, memberPostCode, memberStatus, memberBirthDay, memberEntryDay, attachmentMemberRequest, attachmentDebitAuthorization, attachmentAdditionalSheet });
        history.push("/");
    } catch (e) {
        onError(e);
        setIsLoading(false);
    }
  }

  function createMember(newMember) {
    return API.post("members", "/members", {
      body: newMember
    });
  }

  return (
   
    <div className="NewMember">
        <Row>
            <Col sm={1} />
            <Col sm={5}>
                <Form onSubmit={handleSubmit}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="memberType">
                                <Form.Label>Mitgliedstyp</Form.Label>
                                <Form.Control as="select" value={memberType} onChange={(e) => setMemberType(e.target.value)}>
                                    <option value="keine Auswahl">bitte Auswählen</option>
                                    <option value="Vollmitglied">Vollmitglied</option>
                                    <option value="Partner">(Ehe)Partner</option>
                                    <option value="Jugendlicher">Jugendlicher</option>
                                    <option value="Probemitglied">Probemitglied</option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="memberFirstName">
                                <Form.Label>Vorname</Form.Label>
                                <Form.Control value={memberFirstName} type="text" onChange={(e) => setMemberFirstName(e.target.value)} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="memberSurName">
                                <Form.Label>Nachname</Form.Label>
                                <Form.Control value={memberSurName} type="text" onChange={(e) => setMemberSurName(e.target.value)} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="memberPostCode">
                                <Form.Label>Postleitzahl</Form.Label>
                                <Form.Control value={memberPostCode} type="text" onChange={(e) => setMemberPostCode(e.target.value)} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="memberCity">
                                <Form.Label>Ort</Form.Label>
                                <Form.Control value={memberCity} type="text" onChange={(e) => setMemberCity(e.target.value)} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="memberStreet">
                                <Form.Label>Straße</Form.Label>
                                <Form.Control value={memberStreet} type="text" onChange={(e) => setMemberStreet(e.target.value)} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="memberBirthDay">
                                <Form.Label>Geburtsdatum</Form.Label>
                                <Form.Control value={memberBirthDay} type="text" onChange={(e) => setMemberBirthDay(e.target.value)} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="memberEntryDay">
                                <Form.Label>Eintrittsdatum</Form.Label>
                                <Form.Control value={memberEntryDay} type="text" onChange={(e) => setMemberEntryDay(e.target.value)} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="MemberRequest">
                                <Form.File onChange={handleFileMemberRequestChange} label="Mitgliedsantrag hochladen" custom="true" data-browse="durchsuchen"/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="DebitAuthorization">
                                <Form.File onChange={handleFileDebitAuthorizationChange} label="Bankeinzug hochladen" custom="true" data-browse="durchsuchen"/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="AdditionalSheet">
                                <Form.File onChange={handleFileAdditionalSheetChange} label="Zusatzbogen hochladen" custom="true" data-browse="durchsuchen" />
                            </Form.Group>
                        </Form.Row>
                        <Button block size="medium" className={classes.button} startIcon={<SaveIcon />} color="default" variant="contained"  type="submit" isLoading={isLoading} disabled={!validateForm()}>Mitgliedsdaten speichern</Button>
                </Form>
            </Col>
        </Row>
    </div>
  );
}