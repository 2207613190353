const config = {
    MAX_ATTACHMENT_SIZE: 15000000,
    s3: {
        REGION: "eu-central-1",
        BUCKET: "vereinsmanager.hmt.documents",
    },
    apiGateway: {
        REGION: "eu-central-1",
        URL: "https://uvwhynu671.execute-api.eu-central-1.amazonaws.com/prod",
    },
    cognito: {
        REGION: "eu-central-1",
        USER_POOL_ID: "eu-central-1_CIJYPPN4p",
        APP_CLIENT_ID: "7hov9lf4omoa4l6hn8ltr76t13",
        IDENTITY_POOL_ID: "eu-central-1:fb45dcd7-0805-4d4b-8a81-a195e3558f92",
    },
};

export default config;