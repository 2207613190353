import React, { useRef, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import { onError } from "../libs/errorLib";
import Form from "react-bootstrap/Form";
// import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./EditMember.css";
import { s3Upload } from "../libs/awsLib";
import { Row, Col } from "react-bootstrap";

// Styling
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAdd from '@material-ui/icons/PersonAdd';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function Members() {
  const classes = useStyles();
  const MemberRequest = useRef(null);
  const DebitAuthorization = useRef(null);
  const AdditionalSheet = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const [member, setMember] = useState(null);
  const [memberFirstName, setMemberFirstName] = useState("");
  const [memberSurName, setMemberSurName] = useState("");
  const [memberType, setMemberType] = useState("");
  const [memberStreet, setMemberStreet] = useState("");
  const [memberCity, setMemberCity] = useState("");
  const [memberPostCode, setMemberPostCode] = useState("");
  const [memberStatus, setMemberStatus] = useState("");
  const [memberBirthDay, setMemberBirthDay] = useState("");
  const [memberEntryDay, setMemberEntryDay] = useState("");
  const [memberCellPhone, setMemberCellPhone] = useState("");
  const [memberPhone, setMemberPhone] = useState("");
  const [memberMail, setMemberMail] = useState("");
  const [dvgNumber, setDvgNumber] = useState("");
  const [memberPayment, setMemberPayment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);

  useEffect(() => {
    function loadMember() {
      return API.get("members", `/members/${id}`);
    }

    async function onLoad() {
      try {
        const member = await loadMember();
        const { memberPayment, dvgNumber, memberFirstName, memberSurName, memberType, memberPostCode, memberStatus, memberCity, memberStreet, memberBirthDay, memberEntryDay, memberPhone, memberCellPhone, memberMail, attachmentMemberRequest, attachmentDebitAuthorization, attachmentAdditionalSheet } = member;

        if (attachmentMemberRequest) {
          member.attachmentMemberRequestURL = await Storage.vault.get(attachmentMemberRequest);
        }
        if (attachmentDebitAuthorization) {
          member.attachmentDebitAuthorizationURL = await Storage.vault.get(attachmentDebitAuthorization);
        }
        if (attachmentAdditionalSheet) {
          member.attachmentAdditionalSheetURL = await Storage.vault.get(attachmentAdditionalSheet);
        }
        member.attachmentMembershipRequestURL = await Storage.vault.get(memberSurName + "-" + memberFirstName + "/Anmeldung-dvg-" + memberFirstName + "-" + memberSurName + ".pdf") 
        setMemberFirstName(memberFirstName);
        setMemberSurName(memberSurName);
        setMemberType(memberType);
        setMemberPostCode(memberPostCode);
        setMemberStatus(memberStatus);
        setMemberCity(memberCity);
        setMemberStreet(memberStreet);
        setMemberBirthDay(memberBirthDay);
        setMemberEntryDay(memberEntryDay);
        setMemberPhone(memberPhone);
        setMemberCellPhone(memberCellPhone);
        setMemberMail(memberMail);
        setDvgNumber(dvgNumber);
        setMemberPayment(memberPayment);
        setMember(member);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  function validateForm() {
    return memberSurName.length > 0;
  }
  
  function formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }
  
  function handleFileMemberRequestChange(event) {
    MemberRequest.current = event.target.files[0];
  }
  function handleFileDebitAuthorizationChange(event) {
    DebitAuthorization.current = event.target.files[0];
  }
  function handleFileAdditionalSheetChange(event) {
    AdditionalSheet.current = event.target.files[0];
  }
  
  function saveMember(member) {
    return API.put("members", `/members/${id}`, {
      body: member
    });
  }
  
  async function handleSubmit(event) {
    let attachmentMemberRequest;
    let attachmentDebitAuthorization;
    let attachmentAdditionalSheet;
  
    event.preventDefault();
  
    if (MemberRequest.current && MemberRequest.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }
    if (DebitAuthorization.current && DebitAuthorization.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }
    if (AdditionalSheet.current && AdditionalSheet.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }
  
    setIsLoading(true);
    try {
      if (MemberRequest.current) {
        attachmentMemberRequest = await s3Upload(MemberRequest.current, memberSurName, memberFirstName, 'antrag');
      }
      if (DebitAuthorization.current) {
        attachmentDebitAuthorization = await s3Upload(DebitAuthorization.current, memberSurName, memberFirstName, 'bankeinzug');
      }
      if (AdditionalSheet.current) {
        attachmentAdditionalSheet = await s3Upload(AdditionalSheet.current, memberSurName, memberFirstName, 'zusatz');
      }
  
      await saveMember({
        memberType,
        memberFirstName,
        memberSurName,
        memberPostCode,
        memberStatus,
        memberCity,
        memberStreet,
        memberBirthDay,
        memberEntryDay,
        memberPhone,
        memberCellPhone,
        memberMail,
        dvgNumber,
        memberPayment,
        attachmentMemberRequest: attachmentMemberRequest || member.attachmentMemberRequest,
        attachmentDebitAuthorization: attachmentDebitAuthorization || member.attachmentDebitAuthorization,
        attachmentAdditionalSheet: attachmentAdditionalSheet || member.attachmentAdditionalSheet
      });
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  function deleteMember() {
    return API.del("members", `/members/${id}`, {
      body: member
    });
  }
  
  async function handleDelete(event) {
    event.preventDefault();
  
    const confirmed = window.confirm(
      "Are you sure you want to delete this member?"
    );
  
    if (!confirmed) {
      return;
    }
  
    setIsDeleting(true);
  
    try {
      await handleUnRegister();
      await deleteMember();
      history.push("/");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }

  async function handleRegister() {
    try {
      await fetch('https://rqzwuj7sel.execute-api.eu-central-1.amazonaws.com/dev/pdf', {
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          memberSurName: memberSurName,
          memberFirstName: memberFirstName,
          memberStreet: memberStreet,
          memberPostCode: memberPostCode,
          memberCity: memberCity,
          memberBirthDay: memberBirthDay,
          memberEntryDay: memberEntryDay,
          memberPhone: memberPhone,
          memberCellPhone: memberCellPhone,
          memberMail: memberMail,
          memberDvgNumber: dvgNumber,
          memberPayment: memberPayment,
          memberType: memberType,
          memberAction: 'Anmeldung'
        })
      })
      history.push("/")
    } catch (error) {
      onError(error);
      setIsRegistering(false);
    }
  }

  async function handleUnRegister() {
    try {
      await fetch('https://rqzwuj7sel.execute-api.eu-central-1.amazonaws.com/dev/pdf', {
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          memberSurName: memberSurName,
          memberFirstName: memberFirstName,
          memberStreet: memberStreet,
          memberPostCode: memberPostCode,
          memberCity: memberCity,
          memberBirthDay: memberBirthDay,
          memberEntryDay: memberEntryDay,
          memberPhone: memberPhone,
          memberCellPhone: memberCellPhone,
          memberMail: memberMail,
          memberDvgNumber: dvgNumber,
          memberPayment: memberPayment,
          memberType: memberType,
          memberAction: 'Abmeldung'
        })
      })
      history.push("/")
    } catch (error) {
      onError(error);
      setIsRegistering(false);
    }
  }
  
  return (
    <div className="Members">
      <Row>
        <Col sm={1} />
        <Col sm={5}>
          <h3>Mitgliedsdaten</h3>
          {member && (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Form.Group as={Col} controlId="memberType">
                        <Form.Label>Mitgliedstyp</Form.Label>
                        <Form.Control as="select" value={memberType} onChange={(e) => setMemberType(e.target.value)}>
                            <option value="keine Auswahl">bitte Auswählen</option>
                            <option value="Vollmitglied">Vollmitglied</option>
                            <option value="Partner">(Ehe)Partner</option>
                            <option value="Jugendlicher">Jugendlicher</option>
                            <option value="Probemitglied">Probemitglied</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="memberStatus">
                        <Form.Label>Mitgliedstyp</Form.Label>
                        <Form.Control as="select" value={memberStatus} onChange={(e) => setMemberStatus(e.target.value)}>
                            <option value="keine Auswahl">bitte Auswählen</option>
                            <option value="inactive">inactive</option>
                            <option value="reported">reported</option>
                            <option value="active">active</option>
                            <option value="deleted">deleted</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="memberFirstName">
                        <Form.Label>Vorname</Form.Label>
                        <Form.Control value={memberFirstName} type="text" onChange={(e) => setMemberFirstName(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="memberSurName">
                        <Form.Label>Nachname</Form.Label>
                        <Form.Control value={memberSurName} type="text" onChange={(e) => setMemberSurName(e.target.value)} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="memberPostCode">
                        <Form.Label>Postleitzahl</Form.Label>
                        <Form.Control value={memberPostCode} type="text" onChange={(e) => setMemberPostCode(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="memberCity">
                        <Form.Label>Ort</Form.Label>
                        <Form.Control value={memberCity} type="text" onChange={(e) => setMemberCity(e.target.value)} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="memberStreet">
                        <Form.Label>Straße</Form.Label>
                        <Form.Control value={memberStreet} type="text" onChange={(e) => setMemberStreet(e.target.value)} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="memberBirthDay">
                        <Form.Label>Geburtsdatum</Form.Label>
                        <Form.Control value={memberBirthDay} type="text" onChange={(e) => setMemberBirthDay(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="memberEntryDay">
                        <Form.Label>Eintrittsdatum</Form.Label>
                        <Form.Control value={memberEntryDay} type="text" onChange={(e) => setMemberEntryDay(e.target.value)} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="dvgNumber">
                        <Form.Label>DVG Nummer</Form.Label>
                        <Form.Control value={dvgNumber} type="text" onChange={(e) => setDvgNumber(e.target.value)} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="memberCellPhone">
                        <Form.Label>Handy Nummer</Form.Label>
                        <Form.Control value={memberCellPhone} type="text" onChange={(e) => setMemberCellPhone(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="memberPhone">
                        <Form.Label>Festnetznummer</Form.Label>
                        <Form.Control value={memberPhone} type="text" onChange={(e) => setMemberPhone(e.target.value)} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="memberMail">
                        <Form.Label>E-Mail</Form.Label>
                        <Form.Control value={memberMail} type="text" onChange={(e) => setMemberMail(e.target.value)} />
                    </Form.Group>
                </Form.Row>
                <hr style={{ color : red[300], height: 5}} />
                <br />
                <Form.Row>
                    <Form.Group as={Col} controlId="memberPayment">
                        <Form.Label>Zahlungsart</Form.Label>
                        <Form.Control as="select" value={memberPayment} onChange={(e) => setMemberPayment(e.target.value)}>
                            <option value="keine Auswahl">bitte Auswählen</option>
                            <option value="Einzug">Bankeinzug</option>
                            <option value="Bar">Barzahlung</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                <hr style={{ color : red[300], height: 5}} />
                <br />
                <Form.Row>
                    <Form.Group as={Col} controlId="MembershipRequest">
                      {"Anmeldung-dvg-" + memberFirstName + "-" + memberSurName + ".pdf" && (
                        <p>
                          <a target="_blank" rel="noopener noreferrer" href={member.attachmentMembershipRequestURL}>Anmeldung-dvg.pdf</a>
                        </p>
                      )}
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="MemberRequest">
                      <Form.File onChange={handleFileMemberRequestChange} label="Mitgliedsantrag ändern" custom="true" data-browse="durchsuchen"/>
                      {member.attachmentMemberRequest && (
                        <p>
                          <a target="_blank" rel="noopener noreferrer" href={member.attachmentMemberRequestURL}>
                            {formatFilename(member.attachmentMemberRequest)}
                          </a>
                        </p>
                      )}
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="DebitAuthorization">
                      <Form.File onChange={handleFileDebitAuthorizationChange} label="Bankeinzug ändern" custom="true" data-browse="durchsuchen"/>
                      {member.attachmentDebitAuthorization && (
                        <p>
                          <a target="_blank" rel="noopener noreferrer" href={member.attachmentDebitAuthorizationURL}>
                            {formatFilename(member.attachmentDebitAuthorization)}
                          </a>
                        </p>
                      )}
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="AdditionalSheet">
                      <Form.File onChange={handleFileAdditionalSheetChange} label="Zusatzbogen ändern" custom="true" data-browse="durchsuchen" />
                      {member.attachmentAdditionalSheet && (
                        <p>
                          <a target="_blank" rel="noopener noreferrer" href={member.attachmentAdditionalSheetURL}>
                            {formatFilename(member.attachmentAdditionalSheet)}
                          </a>
                        </p>
                      )}
                    </Form.Group>
                </Form.Row>
                <Button block size="medium" className={classes.button} startIcon={<SaveIcon />} color="default" variant="contained" type="submit" isLoading={isLoading} disabled={!validateForm()}>Mitgliedsdaten speichern</Button>
                <Button block size="medium" className={classes.button} startIcon={<PersonAdd />}color="primary" variant="contained" onClick={handleRegister} isLoading={isRegistering}>Mitglied beim DVG anmelden</Button>
                <Button block size="medium" className={classes.button} startIcon={<DeleteIcon />} color="secondary" variant="contained" onClick={handleDelete} isLoading={isDeleting}>Mitglied beim DVG abmelden</Button>
            </Form>
          )}
        </Col>
        <Col sm={4}><h3>Dokumente</h3></Col>
      </Row>
    </div>
  );
}